.radioCircle {
    border-radius: 50px/50px;
    border: solid 2px #5e5253;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.selectedRb {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background-color: #5e5253;
  }

  .inner-circle{
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    border-color: blue;
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    top:0;
    left:0;
    pointer-events:none;
  }

  .checkmark {
    display:inline-block;
    width: 22px;
    height:22px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}

.checkmark_stem {
    position: absolute;
    width: 6px;
    height: 20px;
    background-color: #5e5253;
    left: 7px;
    top: -5px;
}

.checkmark_kick {
    position: absolute;
    width: 10px;
    height: 5px;
    background-color: #5e5253;
    left: 3px;
    top: 10px;
}